@use '../abstracts/variables_new.scss' as *;

.attachments-wrapper-v2 {
  margin-bottom: 50px;
  padding: 24px;
  background: rgba($bg-hover, 0.33);
  padding: 36px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 0.9375rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: $color-indigo;
    @media (max-width: $break-md-max) {
      font-size: 1rem;
    }
  }

  &__inner {
    margin-bottom: 10px;

    .checkbox-v2 {
      margin-bottom: 5px;
    }
  }

  &__note {
    font-size: 0.8125rem;
    // color: $secondary-text-color;
  }
}