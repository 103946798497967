@use '../abstracts/variables_new.scss' as *;

.help-article {

  &__head {
    margin-bottom: 40px;
    border-bottom: 1px solid #EBECF0;
    padding-bottom: 20px;

    h2 {
      font-size: 1.875rem;
      font-weight: 500;
    }

    p {
      font-size: 0.75rem;
      color: #aaa;
      margin-top: 10px;
    }
  }

  &__body {

    h1,
    h2 {
      margin-bottom: 15px;
      color: $color-indigo;
      font-family: unset ;
    }

    span {
      font-family: unset !important;
    }

    p {
      margin-bottom: 15px;
      font-family: unset;
    }

    img {
      margin-top: 10px;
    }

    a {
      color: $color-indigo;
      font-weight: 500;
      text-decoration: none;
      transition: color .3s ease;
      max-width: 100%;

      &:hover {
        color: darken($color-indigo, 10%);
      }
    }

    .video-title {
      display: inline-block;

      img {
        display: inline-block;
        margin-right: 8px;
        margin-top: 0;
      }
    }
  }

  &__foot {

    ul {
      margin-top: 30px;

      li {
        margin-bottom: 10px;

        span {
          display: flex;
          align-items: center;
          color: #aaa;
          cursor: pointer;
          transition: color .3s ease;

          &:hover {
            color: $primary-color;
          }

          svg {
            margin-right: 10px;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}